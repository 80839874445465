// Form states and alerts
//
// Define colors for form feedback states and, by default, alerts.
$state-success-text: $bmd-inverse !default;
$state-success-bg: $success !default;

$state-info-text: $bmd-inverse !default;
$state-info-bg: $info !default;

$state-warning-text: $bmd-inverse !default;
$state-warning-bg: $warning !default;

$state-danger-text: $bmd-inverse !default;
$state-danger-bg: $danger !default;

$state-rose-bg: $rose !default;
