//
// Modals
// Material Design element Dialogs
// --------------------------------------------------
.modal-dialog {
  & .modal-content{
    @include shadow-z-5();
    border-radius: $border-radius-large;
    border: none;

    .card-signup{
      margin: 0;

      .modal-header{
        padding-top: 0;
      }
    }
  }

  // Modal header
  // Top section of the modal w/ title and dismiss
  .modal-header {
    border-bottom: none;
    padding-top:    24px;
    padding-right:  24px;
    padding-bottom: 0;
    padding-left:   24px;
  }
  // Modal body
  // Where all modal content resides (sibling of .modal-header and .modal-footer)
  .modal-body {
    padding-top:    24px;
    padding-right:  24px;
    padding-bottom: 16px;
    padding-left:   24px;
  }
  // Footer (for actions)
  .modal-footer {
    border-top: none;
    padding: 24px;

    &.text-center{
        text-align: center;
    }

    button {
      margin: 0;
      padding-left: 16px;
      padding-right: 16px;
      width: auto;
      &.pull-left {
        padding-left: 5px;
        padding-right: 5px;
        position: relative;
        left: -5px;
      }
    }
  }
  .modal-body + .modal-footer {
    padding-top: 0;
  }
}
.modal-backdrop {
  background: rgba(0,0,0,0.5);
}

.modal {
  .modal-header .close {
    color: $gray-light;

    &:hover,
    &:focus {
      opacity: 1;
    }

    i {
      font-size: 16px;
    }
  }
}
